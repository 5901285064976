<template>
  <div id="app">
    <notifications></notifications>
    <router-view v-if="render" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      render: false,
    };
  },
  created() {
    if (
      localStorage.getItem(`${this.$tokenName}`) &&
      localStorage.getItem(`${this.$tokenName}`) != "undefined"
    ) {
      let jwt = localStorage.getItem(`${this.$tokenName}`);
      this.$api.auth
        .validateToken(jwt)
        .then((res) => {
          if (res.data.ok == 1) {
            this.$store
              .dispatch("auth/login", res.data)
              .then(() => {
                // this.initLoad();
                this.render = true;
              })
              .catch(() => {
                // this.$store.commit("utils/error");
              });
          } else {
            alert("Invalid token");
            this.$store.dispatch("auth/logout");
            this.render = true;
            this.$router.push("/");
            // window.location = "/";
          }
          // this.render = true;
        })
        .catch(() => {
          this.$store.dispatch("auth/logout");
          this.render = true;
          this.$router.push("/");
        });
    } else {
      console.log("No token");
      this.$store.dispatch("auth/logout");
      this.render = true;
    }
  },
};
</script>
