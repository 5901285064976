import axios from 'axios'

const prefix = "/investor/";

export default {
    async uploadInvestorDoc(userUuid, payload) {
        let json = await axios.post(
            "/documents/" + userUuid,
            payload,
            {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }
        );
        return json;
    },

    async getInvestorDocs(userUuid) {
        let json = await axios.get("/documents/" + userUuid);
        return json;
    },

    async deleteInvestorDoc(userUuid, fileUuid) {
        let json = await axios.delete("/documents/" + userUuid + "/" + fileUuid);
        return json;
    },

}