import axios from "axios";

const prefix = "/regforms"

export default {

    async statuses() {
        let json = await axios.get("/statuses");
        return json;
    },

    async getStatus(status) {
        let json = await axios.get(prefix + "/status/" + status);
        return json;
    },

    async updateStatus(id, payload) {
        let json = await axios.post(prefix + "/" + id + "/status/", payload);
        return json;
    },

};
