import axios from "axios";

const prefix = "/dashboard";

export default {

    async lifeTimeStats() {
        let json = await axios.get("/returns/lifetime");
        return json;
    },

    async highlights() {
        let json = await axios.get(prefix + "/highlights");
        return json;
    },

    async search(searchstr) {
        let json = await axios.post(prefix + "/search", { searchstr: searchstr });
        return json;
    },


}