import Vue from 'vue';
import store from './store/index';

const mixins = Vue.mixin({
    methods: {

        // money
        currency() {
            return store.getters['money/currency'];
        },


        capitalizeFirstLetter: str => str.charAt(0).toUpperCase() + str.slice(1),

        systemError(value = true) {
            store.dispatch('utils/systemError', value);
        },

        load() {
            store.dispatch('utils/loading');
        },

        loaded() {
            store.dispatch('utils/loaded');
        },

        saving() {
            store.dispatch('utils/saving');
        },

        saved() {
            store.dispatch('utils/saved');
        },

        errorToast: function () {
            this.$toasted.show("There was an error, please try again");
        },

        savedToast: function () {
            this.$toasted.show("Saved");
        },

        hasRole: function (role) {
            let roles = store.getters['auth/user'].roles;
            if (store.getters['auth/user'].is_super) {
                return true;
            }
            else {
                if (roles.includes(role)) {
                    return true;
                }
                else {
                    return false;
                }
            }
        },

        getMonths() {
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];
            return monthNames;
        },

        getYears() {
            let baseYear = 2019;
            let date = new Date();
            let currentYear = date.getFullYear() + 3;
            let years = [];
            for (let i = baseYear; i <= currentYear; i++) {
                years.push(i);
            }
            return years;
        },

        getCurrentYear() {
            let date = new Date();
            return date.getFullYear();
        },

        getCurrentMonth() {
            let date = new Date();
            return date.getMonth();
        }
    }
});

export default mixins;