<template>
  <base-nav class="navbar-top navbar-dark" id="navbar-main" :show-toggle-button="false" expand>
    <form
      class="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto"
      @submit.prevent="searchUserSubmit"
    >
      <div class="form-group mb-0">
        <base-input
          placeholder="Search investor by name or mobile"
          class="input-group-alternative"
          alternative
          addon-right-icon="fas fa-search"
          v-model="searchInvestorString"
        ></base-input>
      </div>
    </form>
    <ul class="navbar-nav align-items-center d-none d-md-flex">
      <li class="nav-item dropdown">
        <base-dropdown class="nav-link pr-0">
          <div class="media align-items-center" slot="title">
            <span class="avatar avatar-sm rounded-circle">
              <img alt="Image placeholder" src="/img/theme/icons8-user-100.png" />
            </span>
            <div class="media-body ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm font-weight-bold">{{ user.name }}</span>
            </div>
          </div>

          <template>
            <div class="dropdown-header noti-title">
              <h6 class="text-overflow m-0">Welcome!</h6>
            </div>
            <!-- <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-single-02"></i>
              <span>My profile</span>
            </router-link>
            <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-settings-gear-65"></i>
              <span>Settings</span>
            </router-link>
            <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-calendar-grid-58"></i>
              <span>Activity</span>
            </router-link>
            <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-support-16"></i>
              <span>Support</span>
            </router-link>
            <div class="dropdown-divider"></div>-->
            <a href="#" @click="logout()" class="dropdown-item">
              <i class="ni ni-user-run"></i>
              <span>Logout</span>
            </a>
          </template>
        </base-dropdown>
      </li>
    </ul>

    <modal :show.sync="searchModal">
      <template slot="header">
        <h5 class="modal-title" id="exampleModalLabel">Search results</h5>
      </template>
      <div>
        <div class="table-responsive">
          <base-table thead-classes="thead-light" :data="searchResults">
            <template slot="columns">
              <th>Name</th>
              <th>mobile</th>
              <th></th>
            </template>

            <template slot-scope="{row}">
              <td>{{row.name}}</td>
              <td>{{row.mobile}}</td>
              <td>
                <span
                  class="badge badge-primary pointer"
                  @click="$router.push('/investor/' + row.uuid); searchModal=false"
                >Open</span>
              </td>
            </template>
          </base-table>
        </div>
      </div>
      <template slot="footer">
        <base-button type="secondary" @click="searchModal = false">Close</base-button>
      </template>
    </modal>
  </base-nav>
</template>
<script>
export default {
  data() {
    return {
      searchModal: false,
      searchResults: [],
      searchInvestorString: "",
      activeNotifications: false,
      showMenu: false,
      searchQuery: "",
      user: JSON.parse(localStorage.getItem("user"))
    };
  },
  mounted() {
    // console.log(localStorage.getItem("user"));
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    searchUserSubmit(e) {
      e.preventDefault();
      console.log("Search submit");
      api.dashboard.search(this.searchInvestorString).then(res => {
        this.searchResults = res.data.data;
        this.searchModal = true;
      });
    },

    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        document.location = "/";
      });
      return false;
    }
  }
};
</script>
