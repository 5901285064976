import axios from "axios";

const prefix = "/payouts";

export default {

  async userLifeTimeStats(uuid) {
    let json = await axios.get("/returns/lifetime/" + uuid);
    return json;
  },

  async payoutForecast(payload) {
    let json = await axios.post("/returns/payout_forecast/", payload);
    return json;
  },

  async payoutForecastProjection(payload) {
    let json = await axios.post("/returns/payout_forecast_projection?" + Math.random(), payload);
    return json;
  },

  async userPayoutForecast(userUuid) {
    let json = await axios.get("/returns/payout_forecast/" + userUuid);
    return json;
  },

  async eligiblePayout() {
    let json = await axios.get("/returns/payout_eligible");
    return json;
  },

  async pending() {
    let json = await axios.get(prefix + "/status/pending");
    return json;
  },

  async status(status, month, year) {
    let json = await axios.get(prefix + "/status/" + status + "?month=" + month + "&year=" + year);
    return json;
  },

  async pendingTotal() {
    let json = await axios.get("/returns/pending_total");
    return json;
  },

  async pendingUserPayouts(uuid) {
    let json = await axios.get(prefix + "/user/" + uuid);
    return json;
  },

  async uploadPaymentRcpt(payoutReqUuid, payload) {
    let json = await axios.post(
      prefix + "/withdraw/" + payoutReqUuid + "/rcpt",
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
    return json;
  },

  async payoutStatusChange(payoutReqUuid, status) {
    let json = await axios.put(prefix + "/withdraw/" + payoutReqUuid + "/rcpt/" + status);
    return json;
  },

  async reinvest(uuid, project_uuid, payload) {
    let json = await axios.post(
      prefix + "/reinvest/" + uuid + "/project/" + project_uuid,
      payload
    );
    return json;
  }
};
