/*!

=========================================================
* Vue Argon Dashboard - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import './registerServiceWorker'
import ArgonDashboard from './plugins/argon-dashboard'
import api from "@/api/index"
import store from './store/index'
// import axios from 'axios'
import mixins from "./mixins"
import './filters'


Vue.prototype.$tokenName = process.env.VUE_APP_TOKEN_NAME;
Vue.prototype.$api = api;
window.tokenName = process.env.VUE_APP_TOKEN_NAME;

Vue.config.productionTip = false

Vue.use(ArgonDashboard)
new Vue({
  router,
  store,
  mixins: [mixins],
  render: h => h(App)
}).$mount('#app')
