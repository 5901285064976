import auth from '../../api/auth';
import axios from "axios";
// import VueJwtDecode from "vue-jwt-decode";

const state = {
    count: 1,
    authenticated: false,
    isLoggedIn: false,
    auth_token: null,
    // token: localStorage.getItem('token') || '',
    user: {},
}

const getters = {

    token: state => {
        return state.auth_token;
    },

    isAuth: (state) => {
        return !!state.auth_token;
    },

    isLoggedIn: (state) => {
        return !!state.auth_token;
    },

    user: () => {
        return JSON.parse(localStorage.getItem("user"));
    },


}

const actions = {

    async login({ commit }, payload) {

        return new Promise((resolve, reject) => {
            try {
                localStorage.setItem(`${tokenName}`, payload.access_token);
                commit('auth_success', { token: payload.access_token, user: payload.user });
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + payload.access_token;
                localStorage.setItem(`user`, JSON.stringify(payload.user))
                resolve()
            }
            catch (err) {
                reject(err)
            }

        })


        // await dispatch('getMe');
    },

    async logout({ commit }) {
        // eslint-disable-next-line
        return new Promise((resolve, reject) => {
            try {
                commit('logout')
                localStorage.removeItem(`${tokenName}`)
                delete axios.defaults.headers.common['Authorization']
                resolve()
            }
            catch (err) {
                reject(err)
            }

        })
    },

    async getMe({ commit, dispatch }) {
        await auth.user().then(res => {
            commit('SET_USER', { user: res.data })
        })
            .catch(() => {
                dispatch('logout');
                // document.location = '/login'
            })
    },

}

const mutations = {

    logout(state) {
        // state.status = ''
        state.auth_token = ''
        state.isLoggedIn = false
        state.user = {}
    },

    auth_success(state, payload) {
        // state.status = 'success'
        state.auth_token = payload.token
        state.user = payload.user
        state.roles = payload.user.roles
    },

    INCREMENT(state) {
        state.count++
    },

    SET_AUTH(state, { authStatus }) {
        // eslint-disable-next-line
        console.log("Setting auth")
        state.authenticated = authStatus;
    },

    SET_TOKEN(state, { token }) {
        state.auth_token = token;
        localStorage.setItem(`${tokenName}`, state.auth_token)
    },

    SET_USER(state, { user }) {
        state.user = user
        localStorage.setItem('user', JSON.stringify(user))
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}