import axios from "axios";

export default {

    async login(credentials) {
        let json = await axios.post("/auth/login", credentials);
        return json;
    },

    async validateToken(payload) {
        let json = await axios.post("/auth/validateToken?access_token=" + payload);
        return json;
    },

    async user() {
        let json = await axios.post("/auth/me");
        return json;
    },

};
