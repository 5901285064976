<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <div
      class="d-flex align-items-center justify-content-center"
      style="position:fixed; top:0; left: 0; width:100%; height:100%; z-index:2000; background:rgba(255,255,255,0.5)"
      v-if="$store.getters['utils/loading']"
    >
      <div>Loading...</div>
    </div>
    <side-bar :background-color="sidebarBackground" short-title="Apartlease" title="Apartlease">
      <template slot="links">
        <!-- <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-tv-2 text-primary',
            path: '/dashboard'
          }"
        />-->

        <sidebar-item
          v-for="nav in navs"
          :key="nav.id"
          :link="{name: nav.name, icon: nav.icon, path: nav.url}"
        />

        <!-- <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-tv-2 text-primary',
            path: '/dashboard'
          }"
        />

        <sidebar-item
          :link="{name: 'Investors', icon: 'ni ni-bullet-list-67 text-blue', path: '/investors'}"
        />
        <sidebar-item
          :link="{name: 'Top investors', icon: 'ni ni-favourite-28 text-blue', path: '/top-investors'}"
        />
        <sidebar-item
          :link="{name: 'Investments', icon: 'ni ni-pin-3 text-blue', path: '/investments'}"
        />
        <sidebar-item
          :link="{name: 'Cash payouts', icon: 'ni ni-planet text-blue', path: '/payouts'}"
        />
        <sidebar-item
          :link="{name: 'Payouts eligible', icon: 'ni ni-badge text-blue', path: '/elgible-for-payout'}"
        />
        <sidebar-item
          :link="{name: 'Payout forecast', icon: 'ni ni-badge text-blue', path: '/payout-forecast'}"
        />
        <sidebar-item
          :link="{name: 'Projects', icon: 'ni ni-key-25 text-blue', path: '/projects'}"
        />
        <sidebar-item
          :link="{name: 'Projection', icon: 'ni ni-chart-pie-35 text-blue', path: '/projection'}"
        />
        <sidebar-item
          :link="{name: 'Borrowers', icon: 'ni ni-circle-08 text-blue', path: '/borrowers'}"
        />
        <sidebar-item
          v-if="$store.getters['auth/user'].is_super"
          :link="{name: 'Users', icon: 'ni ni-circle-08 text-blue', path: '/users'}"
        />
        <sidebar-item
          v-if="$store.getters['auth/user'].is_super"
          :link="{name: 'Pages', icon: 'ni ni-circle-08 text-blue', path: '/pages'}"
        />-->
        <!-- <sidebar-item :link="{name: 'Icons', icon: 'ni ni-planet text-blue', path: '/icons'}" />
        <sidebar-item :link="{name: 'Maps', icon: 'ni ni-pin-3 text-orange', path: '/maps'}" />
        <sidebar-item
          :link="{name: 'User Profile', icon: 'ni ni-single-02 text-yellow', path: '/profile'}"
        />
        <sidebar-item
          :link="{name: 'Tables', icon: 'ni ni-bullet-list-67 text-red', path: '/tables'}"
        />
        <sidebar-item :link="{name: 'Login', icon: 'ni ni-key-25 text-info', path: '/login'}" />
        <sidebar-item
          :link="{name: 'Register', icon: 'ni ni-circle-08 text-pink', path: '/register'}"
        />-->
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import { FadeTransition } from "vue2-transitions";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition
  },
  data() {
    return {
      navs: [],
      sidebarBackground: "vue" //vue|blue|orange|green|red|primary
    };
  },
  mounted() {
    this.initNav();
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initNav() {
      axios.get("/navs").then(res => {
        this.navs = res.data.data;
        this.$store.commit("utils/SET_PAGES", res.data.data);
      });
    }
  }
};
</script>
<style lang="scss">
</style>
