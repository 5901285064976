// import api from '../../api';

const state = {
    currency: "MVR",
    exchangeRate: 1
}

const getters = {
    currency: state => {
        return state.currency;
    },
    exchangeRate: state => {
        return state.exchangeRate;
    },
}

const actions = {
    setCurrency({ commit }, value) {
        commit('SET_CURRENCY', value)
    },
    setExchangeRate({ commit }, value) {
        commit('SET_EX_RATE', value)
    },
}

const mutations = {
    SET_CURRENCY: (state, value) => {
        state.currency = value;
    },
    SET_EX_RATE: (state, value) => {
        state.exchangeRate = value;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}