// import api from '../../api';

const state = {
    senders: [],
    loading: false,
    saving: false,
    isDarkTheme: false,
    systemError: false,
    currency: "MVR",
    exchangeRate: 1,
    pages: []
}

const getters = {
    senders: state => {
        return state.senders;
    },
    loading: state => {
        return state.loading;
    },
    saving: state => {
        return state.saving;
    },
    isDarkTheme: state => {
        return state.isDarkTheme;
    },
    systemError: state => {
        return state.systemError;
    },
    currency: state => {
        return state.currency;
    },
    pages: state => {
        return state.pages;
    },
}

const actions = {
    // loading
    loading({ commit }) {
        commit('SET_LOADING')
    },
    loaded({ commit }) {
        commit('SET_LOADED')
    },
    // saving
    saving({ commit }) {
        commit('SET_SAVING')
    },
    saved({ commit }) {
        commit('SET_SAVED')
    },
    dark({ commit }) {
        commit('SET_DARK_THEME')
    },
    undark({ commit }) {
        commit('UNSET_DARK_THEME')
    },
    systemError({ commit }, value) {
        commit('SYSTEM_ERROR', value)
    },
    setCurrency({ commit }, value) {
        commit('SET_CURRENCY', value)
    },
}

const mutations = {
    // loading
    SET_LOADING: (state) => {
        state.loading = true;
    },
    SET_LOADED: (state) => {
        state.loading = false;
    },
    // saving
    SET_SAVING: (state) => {
        state.saving = true;
    },
    SET_SAVED: (state) => {
        state.saving = false;
    },
    SET_DARK_THEME: (state) => {
        state.isDarkTheme = true;
    },
    UNSET_DARK_THEME: (state) => {
        state.isDarkTheme = false;
    },
    SYSTEM_ERROR: (state, value) => {
        state.systemError = value;
    },
    SET_CURRENCY: (state, value) => {
        state.currency = value;
    },
    SET_PAGES: (state, value) => {
        state.pages = value;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}