import Vue from 'vue';
// import store from './store/index';

Vue.filter('currency', value => {
    let val = Number.parseFloat(value);
    // let val = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return val.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
});

Vue.filter('date', value => {
    let date = new Date(value);
    return date.toDateString();

});

