import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import utils from './modules/utils'
import money from './modules/money'
// import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth,
        utils,
        money,
    },
    strict: process.env.NODE_ENV !== 'production',
    // plugins: [createPersistedState()]
})