import axios from "axios";

const prefix = "/bonds"

export default {

    async getProjectReturn(projectUuid) {
        let json = await axios.get(prefix + "/projects/" + projectUuid + "/returns");
        return json;
    },

    async postProjectReturn(projectUuid, payload) {
        let json = await axios.post(prefix + "/projects/" + projectUuid + "/returns", payload);
        return json;
    },

    async postBondProject(payload) {
        let json = await axios.post(prefix + "/projects", payload);
        return json;
    },

    async putBondProject(payload, uuid) {
        let json = await axios.put(prefix + "/projects/" + uuid, payload);
        return json;
    },

    async getProjects(filter) {
        let json = await axios.get(prefix + "/projects?filter=" + filter);
        return json;
    },

    async getProjectOwners() {
        let json = await axios.get(prefix + "/owners");
        return json;
    },

    async getProject(uuid) {
        let json = await axios.get(prefix + "/projects/" + uuid);
        return json;
    },

    async createProjectSchedule(uuid) {
        let json = await axios.post(prefix + "/projects/" + uuid + "/schedule");
        return json;
    },

    async createProjectScheduleItem(uuid, payload) {
        let json = await axios.post(prefix + "/projects/" + uuid + "/schedule/scheduleItem", payload);
        return json;
    },

    async getProjectSchedule(uuid) {
        let json = await axios.get(prefix + "/projects/" + uuid + "/schedule");
        return json;
    },

    async resetProjectSchedule(uuid) {
        let json = await axios.delete(prefix + "/projects/" + uuid + "/schedule");
        return json;
    },

    async deleteProjectSchedule(uuid, schUuid) {
        let json = await axios.delete(prefix + "/projects/" + uuid + "/schedule/" + schUuid);
        return json;
    },

    async updateProjectSchedule(uuid, payload) {
        let json = await axios.put(prefix + "/projects/" + uuid + "/schedule/", payload);
        return json;
    },

    async createSchedulePayment(payload) {
        // let json = await axios.post(prefix + "/projects/payments", payload);
        let json = await axios.post(
            prefix + "/projects/payments",
            payload,
            {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }
        );
        return json;
    },

    async updateSchedulePayment(id, payload) {
        // let json = await axios.post(prefix + "/projects/payments", payload);
        let json = await axios.post(
            prefix + "/projects/payments/update/" + id,
            payload,
            {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }
        );
        return json;
    },

    async getBonds(cat) {
        let json = await axios.get(prefix + "/cat/" + cat);
        return json;
    },

    async cats() {
        let json = await axios.get(prefix + "/cats");
        return json;
    },

};
