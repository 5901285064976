<template>
  <div class="main-content bg-default">
    <div
      class="d-flex align-items-center justify-content-center"
      style="position:fixed; top:0; left: 0; width:100%; height:100%; z-index:2000; background:rgba(255,255,255,0.5)"
      v-if="$store.getters['utils/loading']"
    >
      <div>Loading...</div>
    </div>
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8">
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon class="fill-default" points="2560 0 2560 100 0 100" />
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <slide-y-up-transition mode="out-in" origin="center top">
        <router-view></router-view>
      </slide-y-up-transition>
    </div>
    <footer class="py-5">
      <div class="container">
        <div class>
          <div class>
            <div class="copyright text-center text-muted">
              &copy; {{year}}
              Apartlease
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import { SlideYUpTransition } from "vue2-transitions";

export default {
  name: "auth-layout",
  components: {
    SlideYUpTransition
  },
  data() {
    return {
      year: new Date().getFullYear(),
      showMenu: false
    };
  }
};
</script>
<style>
</style>
