import Vue from 'vue'
import axios from 'axios'
import store from '@/store/index'
import auth from './auth'
import invest from './invest'
import bonds from './bonds'
import payout from './payout'
import borrower from './borrower'
import dashboard from './dashboard'
import investor from './investor'

const api = {
    auth,
    invest,
    bonds,
    payout,
    borrower,
    dashboard,
    investor
}

window.api = api;
window.axios = axios;
axios.defaults.baseURL = process.env.VUE_APP_API;

Vue.prototype.$axios = axios;
Vue.prototype.$baseURL = process.env.VUE_APP_API;

const token = localStorage.getItem(`${process.env.VUE_APP_TOKEN_NAME}`);
if (token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}

axios.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        store.dispatch('utils/loading');
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    function (response) {
        store.dispatch('utils/loaded');
        if (response.data.ok == 0) {
            // this.systemError();
            // store.dispatch('utils/systemError', true)
            // return Promise.reject(response);
        }
        return response;
    },
    function (error) {
        if (!error.response) {
            console.log('Network error')
        } else {
            // http status code
            const code = error.response.status
            // response data
            const response = error.response.data

            if (code == 401) {
                // eslint-disable-next-line
                console.log("Not auth");
                // alert("User not authorized")
                // document.location = "/login"
            }

        }

        if (error.response) {


        }
        else {
            // eslint-disable-next-line
            // alert("There was an error, please try again")
            console.log("Unable to reach server");
            // document.location = "/login"
        }
        store.dispatch('utils/loaded')
        store.dispatch('utils/systemError', true)

        return Promise.reject(error);
    }
);

export default api;
// export { default } from "./misc"